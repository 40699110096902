import { Button, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

const HomeForm = ({ responsive, subscribe, status, message }: any) => {

    const {
        handleSubmit,
        reset,
        register,
        control,
        formState: { errors },
    } = useForm();

    const submitHandler = async (data: any) => {
        if(data.EMAIL !== '') {
            subscribe(data)
        }
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(submitHandler)}className="mc__form">
            <Controller
                name="EMAIL"
                control={control}
                render={({ field: { value } }) => (
                    <Grid container spacing={responsive ? 2 : 0} style={{ textAlign: 'center', paddingLeft: `${responsive ? '0px' : '40px'}`, marginTop: '30px' }}>
                        <Grid item md={8} xs={12}>
                            <TextField
                                fullWidth
                                value={value}
                                label={"Typer in your e-mail"}
                                {...register('EMAIL', {
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid Email!"
                                    }
                                })}
                                name="EMAIL"
                                type="email"
                                error={!!errors?.email}
                                helperText={errors?.email ? `${errors.email?.message}` : ''}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='inherit'
                                style={{ padding: '15px 0 15px 0', width: responsive ? '100%' : '80%', background: 'linear-gradient(to right, #00643C, #FD8F00)' }}
                            >
                                Subscribe
                            </Button>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            {status === "sending" && (
                                <div className="mc__alert mc__alert--sending" style={{marginTop:'1rem'}}>
                                    sending...
                                </div>
                                )}
                                {status === "error" && (
                                <div 
                                    className="mc__alert mc__alert--error"
                                    dangerouslySetInnerHTML={{ __html: message }}
                                    style={{marginTop:'1rem', color: 'red'}}
                                />
                                )}
                                {status === "success" && (
                                <div
                                    className="mc__alert mc__alert--success"
                                    dangerouslySetInnerHTML={{ __html: message }}
                                    style={{marginTop:'1rem', color:'green'}}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
            />
        </form>
    )
}

export default HomeForm;