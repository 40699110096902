import { Button, useTheme } from "@mui/material";
import React, { FC, MouseEventHandler, useState } from "react";

interface DisplayButtonProps {
  backgroundColor?: string;
  color?: string;
  fontSize?: number;
  borderWidth?: number;
  children: React.ReactNode;
  onClick?: MouseEventHandler;
}

const DisplayButton: FC<DisplayButtonProps> = (props: DisplayButtonProps) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();

  const backgroundColor = props.backgroundColor ?? theme.palette.primary.main;
  const backgroundOverlay =
    backgroundColor === "transparent"
      ? "transparent"
      : "linear-gradient(90deg, #000A 0%, transparent 100%)";
  const background = `${backgroundOverlay}, ${backgroundColor}`;

  const color = props.color ?? "#FFF";
  const fontSize = props.fontSize ?? 18;

  const baseStyle = {
    fontSize,
    padding: "12px 24px",
    borderColor: color,
    borderStyle: "solid",
    borderWidth: props.borderWidth ?? 4,
    borderRadius: 18,
    transition: "120ms ease-in-out",
    fontWeight: 400,
  };

  const normalStyle = {
    ...baseStyle,
    background,
    color,
  };

  const hoverStyle = {
    ...baseStyle,
    backgroundColor: color,
    color:
      backgroundColor === "transparent"
        ? theme.palette.getContrastText(color)
        : backgroundColor,
     letterSpacing: 2
  };

  return (
    <Button
      variant="outlined"
      style={hover ? hoverStyle : normalStyle}
      onClick={props.onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {props.children}
    </Button>
  );
};

export default DisplayButton;
