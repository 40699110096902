import { createTheme } from "@mui/material";

export const Colors = {
  black: "#272727",
  green: "#00643C",
  brown: "#361D11",
  yellow: "#FD8F00",
  white: "#FFFFFF",
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.yellow,
    },
    secondary: {
      main: Colors.green,
    },
    divider: Colors.brown,
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: '25px 25px 0 0'
        }
      }
    }
  }
});

export default theme;
