import { ImageList, ImageListItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC } from "react";

interface GalleryViewProps {
  description: string;
  galleryImages: Array<string>;
}

const GalleryView: FC<GalleryViewProps> = (props: GalleryViewProps) => {
  
  return (
    <Stack gap={5}>
      <Typography
        variant="body1"
        component="p"
        style={{ textAlign: "justify", whiteSpace: 'pre-line',verticalAlign: 'bottom', lineHeight:'1.7rem' }}
        dangerouslySetInnerHTML={{__html: props.description}}
      >
      </Typography>

      <ImageList variant="masonry" cols={3} rowHeight={164}>
        {props.galleryImages.map((imageUrl) => (
          <ImageListItem key={imageUrl}>
            <img
              src={imageUrl}
              alt={imageUrl.replaceAll("-", " ").replace(/\..*$/, "")}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Stack>
  );
};

export default GalleryView;
