import { FC } from "react";

import Timeline from "@mui/lab/Timeline";
import { Grid, Stack, useMediaQuery } from "@mui/material";

import { useTheme } from "@mui/material";
import HistoryTimelineItem, {
  HistoryTimelineItemProps,
  MobileHistoryTimelineItem,
} from "../../components/HistoryTimelineItem/HistoryTimelineItem";

import { historyEntries } from "../../data";

const AboutPage: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const entriesProps = historyEntries.map((e, i) => {
    let entryProps: HistoryTimelineItemProps = { ...e };

    if (i % 2 === 0) {
      entryProps.flipped = true;
      entryProps.accentColor = theme.palette.secondary.main;
    }

    return entryProps;
  });

  const gridItem = !isMobile ? (
    <Grid item xl={7} lg={8}>
      <Timeline position="alternate">
        {entriesProps.map((entry, i) => (
          <HistoryTimelineItem key={i} {...entry} />
        ))}
      </Timeline>
    </Grid>
  ) : (
    <Grid item xs={11}>
      <Stack>
        {entriesProps.map((entry) => (
          <MobileHistoryTimelineItem {...entry} />
        ))}
      </Stack>
    </Grid>
  );

  return (
    <Grid container justifyContent="center">
      {gridItem}
    </Grid>
  );
};

export default AboutPage;
