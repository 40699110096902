import loadData from "./loader";

export interface SocialMediaLink {
  name: string;
  icon: string;
  link: string;
}

export const socialMediaLinks = loadData<SocialMediaLink>(
  "socialmedia_links.json"
);
