import { InfoOutlined } from '@mui/icons-material';
import { Container, Stack, Tooltip } from '@mui/material';
import { FC } from 'react';
import ContactForm from './ContactForm';

interface ContactProps { }

const ContactPage: FC<ContactProps> = () => (
  <Container maxWidth={"sm"} style={{ textAlign: 'center' }}>
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
      <h1> Get In Touch</h1>
      <Tooltip enterTouchDelay={0} leaveDelay={0} title='Field required with *'>
          <InfoOutlined htmlColor="#41414188" />
      </Tooltip>
    </Stack>
    <br /> <br />
    <ContactForm />
  </Container>
);

export default ContactPage;
