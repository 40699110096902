import { Edit, KeyboardArrowDown } from "@mui/icons-material";
import { Box, Divider, Drawer, IconButton, ImageList, ImageListItem, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { coffeeologyEntries } from "../../data/coffeeology";
import styles from "./Blog.module.scss";

const Blog = (props: any) => {
    const theme = useTheme();
    const blog = coffeeologyEntries.at(props.id - 1);
    const [drawerHeight, setDrawerHeight] = useState('85vh');

    const toggleDrawerHeight = () => {
        setDrawerHeight('100vh');
    }

    const onDrawerClose = () => {
        props.toggleBlog();
        setDrawerHeight('85vh');
    }

    return (
        <Drawer
            anchor='bottom'
            open={props.isBlogOpen}
            onClose={() => onDrawerClose()}
            onScrollCapture={() => toggleDrawerHeight()}
        >
            <Box height={drawerHeight}>
                <div className={styles.imageContainer} style={{ backgroundImage: `linear-gradient(0deg, ${theme.palette.divider}99, transparent), url(${blog?.image})` }}>
                    <IconButton onClick={() => onDrawerClose()} style={{position: 'fixed', left: '50%', transform: 'translateX(-50%)'}}>
                        <KeyboardArrowDown style={{ fontSize: '50px', color: 'white' }} />
                    </IconButton>
                    <h1 className={styles.title}> {blog?.title} </h1>
                </div>
                <Stack spacing={2} direction={'row'} alignItems='center' style={{ marginLeft: "10%", marginBottom: 25, marginTop: 25 }} >
                    <div className={styles.authorImage} style={{ backgroundImage: `linear-gradient(0deg, ${theme.palette.divider}99, transparent), url(${blog?.authorImage})` }}>
                    </div>
                    <Stack spacing={1}>
                        <h1 className={styles.authorName}> {blog?.authorName} </h1>
                        <Stack direction={'row'} alignItems="center" justifyContent="center" display="block" spacing={1}>
                            <Edit style={{ color: 'gray', fontSize: 16 }} />
                            <h1 className={styles.date}> {blog?.createdAt} </h1>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider style={{ width: '90%', marginLeft: '5%', marginBottom: 25 }} />

                   {blog?.blogImages && 
                        <div style={{marginBottom: '1rem', marginLeft:'5%'}}>
                            <ImageList variant="masonry" cols={2} rowHeight={300} sx={{width: '95%'}}>
                                {blog?.blogImages.map((imageUrl: any) => (
                                        <ImageListItem key={imageUrl}>
                                            <img
                                            src={imageUrl}
                                            alt={imageUrl.replaceAll("-", " ").replace(/\..*$/, "")}
                                            loading="lazy"
                                            />
                                        </ImageListItem>
                                ))}
                            </ImageList>
                        </div>
                } 
                
                <div style={{ width: '95%' }}>
                    {blog?.description ? (
                        <>
                            <h1 className={styles.description} dangerouslySetInnerHTML = {{ __html:blog.description}}></h1>
                            <br />
                        </>
                    ) : null}
                </div>
            </Box>
        </Drawer>
    )
}

export default Blog;