import { Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import styles from "./Home.module.scss";
import HomeForm from "./HomeForm";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const url = process.env.REACT_APP_MAILCHIMP_URL;

const HomeSection3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); 
  const responsiveImage = useMediaQuery("(max-width:1400px)");

  return (
    <Grid container alignItems={"center"}>
      {!responsiveImage ? (
        <Grid item xs={1}>
          <img
            src="/assets/img/svg/CircledCoffeeBeans.svg"
            alt="Coffee Beans"
            width={"280"}
          />
        </Grid>
      ) : null}
      <Grid item xs={responsiveImage ? 12 : 10}>
        <Container maxWidth={"md"}>
          <div style={{ textAlign: "center", marginBottom: "15%" }}>
            <h1 className={styles["section3-title"]}>
              {" "}
              Lana Is Available Worldwide{" "}
            </h1>
            <h1 className={styles["section3-body"]}>
              {" "}
              “Our ancestors the ancient Sabaean were famously known for their trade in extremely lucrative spice, frankincense and myrrh, they would cross mountains, seas and deserts to introduce their products to the world.
So its only right that we follow in our ancestors footsteps and provide our coffee 
<br/> <br/> to everyone by crossing <span style={{fontFamily: "Allison", fontSize: "60px"}}>Mountains, Seas and Deserts".</span>{" "}
            </h1>
          </div>
          <div style={{ textAlign: "center" }}>
            <h1 className={styles["section3-title2"]}>
              {" "}
              We Want To Keep You Posted{" "}
            </h1>
            <h1 className={styles["section3-body"]}>
              {" "}
              Subscribe to our Newsletter and be updated on our new products,
              events, promotions direct on your inbox!{" "}
            </h1>

            <MailchimpSubscribe
              url={url as string}
              render={({ subscribe, status, message }) => (
                <HomeForm responsive={isMobile} status={status} message={message} subscribe={subscribe} />
              )}
            />
          </div>
        </Container>
      </Grid>
    </Grid>
  );
};

export default HomeSection3;
