import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC } from "react";
import { HistoryEntry } from "../../data";
import RoundedArrow from "../RoundedArrow/RoundedArrow";

export interface HistoryTimelineItemProps extends HistoryEntry {
  accentColor?: string;
  flipped?: boolean;
}

const HistoryTimelineConnector = (props: {
  inverted?: boolean;
  width?: number;
  radius?: number;
}) => {
  const theme = useTheme();

  const WIDTH = props.width ?? 5;
  const RADIUS = props.radius ?? 8;

  let radiusProps =
    props.inverted ?? false
      ? { borderTopLeftRadius: RADIUS, borderTopRightRadius: RADIUS }
      : { borderBottomLeftRadius: RADIUS, borderBottomRightRadius: RADIUS };

  return (
    <TimelineConnector
      sx={{
        width: WIDTH,
        backgroundColor: theme.palette.divider,
        ...radiusProps,
        m: "0 auto",
      }}
    />
  );
};

const HistoryItemContent = (props: {
  description: string;
  imageUrl?: string;
  children?: React.ReactNode;
}) => (
  <TimelineContent>
    <img
      src={props.imageUrl ?? "/assets/img/png/small-placeholder.png"}
      alt="placeholder"
      style={{ width: "100%" }}
    />
    <p>{props.description}</p>
    {props.children}
  </TimelineContent>
);

export default (props: HistoryTimelineItemProps) => {
  let theme = useTheme();

  return (
    <TimelineItem>
      <TimelineOppositeContent
        sx={{ m: "auto", flex: 1, fontWeight: 600 }}
        align="right"
        variant="body1"
        color="text.secondary"
      >
        {props.additionalText}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <HistoryTimelineConnector />
        <Box style={{ margin: "12px 0px" }}>
          <RoundedArrow
            color={props.accentColor ?? theme.palette.primary.dark}
            angle={props.flipped ?? false ? 0 : 180}
          />
        </Box>
        <HistoryTimelineConnector inverted />
      </TimelineSeparator>
      <TimelineContent>
        <HistoryItemContent description={props.description} imageUrl={props.imageUrl} />
      </TimelineContent>
    </TimelineItem>
  );
};

export const MobileHistoryTimelineItem: FC<HistoryTimelineItemProps> = (
  props: HistoryTimelineItemProps
) => {
  let theme = useTheme();

  return (
    <Stack direction="row">
      <Stack justifyContent="center">
        <HistoryTimelineConnector width={3} />
        <Box style={{ margin: "12px 0px" }}>
          <RoundedArrow
            color={props.accentColor ?? theme.palette.primary.dark}
            height={48}
            width={48}
          />
        </Box>
        <HistoryTimelineConnector inverted width={3} />
      </Stack>
      <Stack>
        <HistoryItemContent description={props.description} imageUrl={props.imageUrl}>
          <Typography
            variant="body2"
            color="text.secondary"
            textOverflow="ellipsis"
            marginY={2}
          >
            {props.additionalText}
          </Typography>
        </HistoryItemContent>
      </Stack>
    </Stack>
  );
};
