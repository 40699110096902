import { useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";

import styles from "./ImageCard.module.scss";

interface ImageCardProps {
  title?: string;
  subTitle?: string;
  imageUrl: string;
  height?: string | number;
  width?: string | number;
  backgroundSize?: string;
  backgroundPosition?: string;
  borderRadius?: string | number;
}

const ImageCard: FC<ImageCardProps> = (props: ImageCardProps) => {
  const theme = useTheme();

  return (
    <div
      style={{
        height: props.height ?? "100%",
        width: props.width ?? "100%",
        background: `url(${props.imageUrl}), linear-gradient(0deg, ${theme.palette.divider}44, transparent)`,
        backgroundBlendMode: "overlay",
        backgroundPosition: props.backgroundPosition ?? "center center",
        backgroundSize: props.backgroundSize ?? "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        borderRadius: props.borderRadius,
      }}
    >
      {props.title || props.subTitle ? (
        <Stack className={styles.content} alignSelf="end" spacing={2}>
          <p className={styles.title}>{props.title}</p>
          <p className={styles.subTitle}>{props.subTitle}</p>
        </Stack>
      ) : null}
    </div>
  );
};

export default ImageCard;
