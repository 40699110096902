import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Container
  } from '@mui/material';
  import { createTheme, ThemeProvider } from "@mui/material/styles";
  import { useForm, SubmitHandler } from 'react-hook-form';
  import { object, string, TypeOf } from 'zod';
  import { zodResolver } from '@hookform/resolvers/zod';
  import { useEffect, useState } from 'react';
  import { LoadingButton } from '@mui/lab';
  import countries from "./countries.json";
  import emailjs from 'emailjs-com';
  import Swal from 'sweetalert';

  const theme = createTheme();
  
  const registerSchema = object({
    firstName: string()
      .nonempty('First Name is required')
      .max(32, 'First Name must be less than 100 characters'),
    lastName: string()
      .nonempty('Last Name is required')
      .max(32, 'Last Name must be less than 100 characters'),
    email: string().nonempty('Email is required').email('Email is invalid'),
    phoneNumber: string()
      .nonempty('Phone Number is required')
      .min(8, 'Phone Number must be more than 8 characters')
      .max(15, 'Phone Number must be less than 32 characters'),
      message: string()
      .nonempty('Message is required')
      .min(8, 'Message must be more than 8 characters')
      .max(100, 'Message must be less than 32 characters'),
      country: string()
      .nonempty('Message is required')
  });
  
  type RegisterInput = TypeOf<typeof registerSchema>;

  const serviceId = process.env.REACT_APP_SERVICE_ID_SECRET ?? '';
  const templateId = process.env.REACT_APP_TEMPLATE_ID_SECRET ?? '';
  const apiEmailJs = process.env.REACT_APP_API_KEY_EMAIL_JS ?? '';

  console.log(serviceId, templateId, apiEmailJs, 'serviceId');
  
  const ContactForm = () => {
    const [loading, setLoading] = useState(false);
  
    const {
      register,
      formState: { errors, isSubmitSuccessful },
      reset,
      handleSubmit,
    } = useForm<RegisterInput>({
      resolver: zodResolver(registerSchema),
    });
  
    useEffect(() => {
      if (isSubmitSuccessful) {
        reset();
      }
    }, [isSubmitSuccessful]);
  
    const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
        console.log(values,'values');
        emailjs.send(serviceId,templateId, values, apiEmailJs)
        .then((response) => {
            Swal({
                icon: 'success',
                title: 'Email send Successfully',
                text: response.text
            });
        }, (err) => {
            Swal({
                icon: 'info',
                title: 'Ooops, something went wrong',
                text: err.text,
            });
        });
    };
  
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{marginTop:'1rem'}}>
                <Box sx={{ maxWidth: '30rem' }}>
                    <Box
                    component='form'
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit(onSubmitHandler)}
                    >
                    <TextField
                        sx={{ mb: 2 }}
                        label='First Name'
                        fullWidth
                        required
                        error={!!errors['firstName']}
                        helperText={errors['firstName'] ? errors['firstName'].message : ''}
                        {...register('firstName')}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        label='Last Name'
                        fullWidth
                        required
                        error={!!errors['lastName']}
                        helperText={errors['lastName'] ? errors['lastName'].message : ''}
                        {...register('lastName')}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        label='Email'
                        fullWidth
                        required
                        type='email'
                        error={!!errors['email']}
                        helperText={errors['email'] ? errors['email'].message : ''}
                        {...register('email')}
                    />
                    <TextField
                        sx={{ mb: 2 }}
                        label='Phone Number'
                        fullWidth
                        required
                        type='number'
                        error={!!errors['phoneNumber']}
                        helperText={errors['phoneNumber'] ? errors['phoneNumber'].message : ''}
                        {...register('phoneNumber')}
                    />
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel> Country </InputLabel>
                                <Select
                                label="Country"
                                id="country"
                                error={!!errors['country']}
                                {...register('country')}
                                MenuProps={{
                                    PaperProps: {
                                    style: {
                                        maxHeight: 40 * 4.5 + 8,
                                        width: 250,
                                    },
                                    },
                                }}
                                >
                                {countries.map((name) => (
                                    <MenuItem key={name.id} value={name.name}>
                                    {name.name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                        <TextField
                        sx={{ mb: 2 }}
                        label='Message'
                        fullWidth
                        required
                        type='text'
                        multiline
                        rows={4}
                        error={!!errors['message']}
                        helperText={errors['message'] ? errors['message'].message : ''}
                        {...register('message')}
                    />
            
                    <LoadingButton
                        variant='contained'
                        fullWidth
                        type='submit'
                        loading={loading}
                        sx={{ py: '0.8rem', mt: '1rem', background: 'linear-gradient(to right, #00643C, #FD8F00)' }}
                    >
                        Submit
                    </LoadingButton>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
  };
  
  export default ContactForm;
  
  