import { Container, Grid, Stack } from "@mui/material";
import HomeCard from "../../components/Card/HomeCard";
import styles from "./Home.module.scss";
import { homeCardEntries } from "../../data/homeCard";

const HomeSection2 = () => {
    return (
        <div className={styles['section2-container']}>
            <Container maxWidth={'xl'}>
                <div className={styles['section2-textContainer']}>
                    <h1 className={styles['section2-title']}> Looking For A Quality Coffee? </h1>
                    <h1 className={styles['section2-body']}>Lana World Trade is a great source for quality Yemeni and Ethiopian coffees. Lana World Trade is a specialty coffee company that imports coffee from Yemen and Ethiopian. We work and support small farmers and export to roasters all over the world. Lana World Trade was founded with the goal of helping small farmers get their coffees into the hands of specialty roasters. If you're looking for truly delicious Yemen or Ethiopian coffees, Lana World Trade should be your go-to source.</h1>
                </div>
                <Grid container justifyContent={'center'} alignItems={'stretch'} spacing={8}>
                    {
                        homeCardEntries.map((entry, i) => {
                            return (
                                <Grid container item lg={4} md={6} xs={12}>
                                    <HomeCard title={entry.title} body={entry.description} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Container>
        </div>
    )
}

export default HomeSection2;