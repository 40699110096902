import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import RoundedButton from "../../components/button/RoundedButton";
import InfoGraphicContent from "../../components/InfoGraphicContent/InfoGraphicContent";
import SocialMediaBar from "../../components/SocialMediaBar/SocialMediaBar";
import { ourStoryEntries, OurStoryEntry } from "../../data/ourStory";
import styles from "./Home.module.scss";

export interface OurStoryProps extends OurStoryEntry {
  flipped?: boolean;
}

const HomeSection1 = () => {
  const responsive = useMediaQuery("(max-width:1500px)");
  return (
    <>
      <div className={styles['section1-textContainer']}>
        <h1 className={styles["section3-title"]}>
          Our Story
        </h1>
      </div>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        columnSpacing={4}
        rowSpacing={3}
      >
        <Grid item xs={12} lg={10}>
          <Stack rowGap={8}>
            {ourStoryEntries.map((entry, i) => {
              let entryProps: OurStoryProps = {
                ...entry,
              };

              if (i % 2 === 0) {
                entryProps.flipped = true;
              }
              return <InfoGraphicContent key={i} {...entryProps}></InfoGraphicContent>
            })}
          </Stack>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'center' }}>
        <RoundedButton
          color="secondary"
          variant="outlined"
          href="/OurCoffee"

          style={{
            color: "black",
            borderWidth: "3px",
            marginTop: "10%",
          }}
        >
          View Our Products
        </RoundedButton>
      </div>
      <Grid container item justifyContent={"center"}>
        <Grid item xs={12} md={10} lg={8} style={{position: 'relative'}}>
          <img
            src="/assets/img/svg/pouringCoffee.svg"
            alt="pouring coffee"
            style={{
              display: responsive ? "none" : "",
              position: "absolute",
              right: 0,
              bottom: "10%",
              width: "20%",
              height: 316,
            }}
          />
          <Card
            className={`${styles["section1-card"]}
            `}
          >
            <CardContent>
              <h1
                className={`${styles["section1-card-title"]}
                `}
              >
                Let's Have A Talk
              </h1>
            </CardContent>
            <CardActions>
              <Box justifyContent="center" display="flex" flexGrow={1}>
                <SocialMediaBar htmlColor="white" fontSize="medium" />
              </Box>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HomeSection1;
