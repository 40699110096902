import { Box, Stack, useTheme } from "@mui/material";
import style from "./Footer.module.scss";
import Grid from "@mui/material/Grid"; // Grid version 2
import SocialMediaBar from "../SocialMediaBar/SocialMediaBar";
import Logo from "../Logo/Logo";

const Footer = () => {
  const theme = useTheme();
  const background = theme.palette.divider;
  const foreground = theme.palette.getContrastText(background);

  const links = [
    { label: "Home", link: "/" },
    { label: "Contact Us", link: "/Contact" },
    { label: "Coffeeology", link: "/Coffeeology" },
    { label: "Careers", link: "#" },
    { label: "Our Coffee", link: "/OurCoffee" },
    { label: "Shipping Terms", link: "#" },
    { label: "History of Coffee", link: "/About" },
    { label: "Privacy Policy", link: "#" },
    { label: "Meet The Team", link: "/Meet" },
    { label: "Terms & Conditions", link: "#" },
  ];

  const FooterTextSection = (props: {
    heading: string;
    items: Array<string>;
  }) => {
    return (
      <Box className={style.textBlock}>
        <b className={style.textHeading}>{props.heading}</b>
        <ul>
          {props.items.map((item, i) => (
            <li key={i} className={style.textItem}>
              {item}
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  const FooterNavigationLink = (props: { label: string; link: string }) => {
    return (
      <a className={style.link} style={{ color: foreground }} href={props.link}>
        {props.label}
      </a>
    );
  };

  return (
    <Box className={style.footerWrapper} sx={{ backgroundColor: background }}>
      <Stack
        sx={{
          color: foreground,
          paddingY: 12,
          paddingX: { md: 18, sm: 10, xs: 4 },
          marginTop: 8,
        }}
        className={style.footer}
      >
        <Logo size={172} />
        <hr className={style.separator} />
        <Grid container>
          <Grid item lg={7} md={6} xs={12}>
            <Stack>
              <FooterTextSection
                heading="Address"
                items={["992 Uxbridge Road", "Hayes, UB4 0RL"]}
              />
              <br />
              <FooterTextSection
                heading="Contact Us"
                items={["0000-0000-000"]}
              />
              <SocialMediaBar fontSize="medium" htmlColor={foreground} />
            </Stack>
          </Grid>
          <Grid
            item
            container
            lg={5}
            md={6}
            xs={12}
            spacing={3}
            alignContent="flex-start"
            sx={{
              textAlign: { xs: "left", md: "right" },
              paddingTop: { xs: 8, md: 0 },
            }}
          >
            {links.map((l, i) => {
              return (
                <Grid key={i} item xs={6}>
                  <FooterNavigationLink {...l} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <hr className={style.separator} />
        <p className={style.copyRightText}>LANA WORLD © 2022 TRADE, LTD</p>
      </Stack>
    </Box>
  );
};

export default Footer;
