import { Grid, useTheme } from "@mui/material";
import { FC } from "react";
import { CompanyInfoEntry } from "../../data";
import ImageCard from "../ImageCard/ImageCard";

export interface CompanyInfoItemProps extends CompanyInfoEntry {
  title: string;
  description: string;
  flipped?: boolean;
}

const CompanyInfoItem: FC<CompanyInfoItemProps> = (
  props: CompanyInfoItemProps
) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction={props.flipped ? "row" : "row-reverse"}
      columnSpacing={4}
      rowSpacing={3}
    >
      <Grid container item xs={12} sm={6} rowGap={3} justifyContent={"center"} alignContent={"center"}>
        <p
          style={{
            fontSize: 56,
            fontWeight: "bold",
            color: theme.palette.secondary.main,
            
          }}
        >
          {props.title}
        </p>
        <br />
        <p
          style={{
            textAlign: "center",
            fontSize: 20,
            lineHeight: 1.67,
            fontWeight: "light",
          }}
        >
          {props.description}
        </p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageCard imageUrl={props.image} height={407} borderRadius={20}/>
      </Grid>
    </Grid>
  );
};
export default CompanyInfoItem;
