import loadData from "./loader";

export interface CoffeeologyEntry {
  id: number;
  title: string;
  description: string;
  image: string;
  authorName: string;
  authorImage: string;
  createdAt: string;
  blogImages: Array<string>;
  blog: any;
}

export const coffeeologyEntries =
  loadData<CoffeeologyEntry>("coffeeology.json");
