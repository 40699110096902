import { Grid, Stack } from "@mui/material";
import { ourCoffeeEntries } from "../../data/ourCoffee";
import OurCoffeeItem, {
  OurCoffeeItemProps,
} from "../../components/OurCoffeeItem/OurCoffeeItem";

const OurCoffeePage = () => {
  return (
    <Grid container justifyContent="center">
      <Stack rowGap={6}>
        {ourCoffeeEntries.map((entry, i) => {
          let entryProps: OurCoffeeItemProps = {
            ...entry,
          };

          if (i % 2 !== 0) {
            entryProps.flipped = true;
          }

          return (
            <Grid
              key={i}
              container
              justifyContent="center"
              style={{
                backgroundColor: entryProps.flipped ? "#D9D9D930" : "",
                padding: "56px 0",
              }}
            >
              <Grid item xs={11} lg={8}>
                <OurCoffeeItem {...entryProps}></OurCoffeeItem>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    </Grid>
  );
};

export default OurCoffeePage;
