import { ElementType, FC } from "react";

import { Box, Link, SvgIcon } from "@mui/material";

import styles from "./SocialMediaBar.module.scss";
import { socialMediaLinks } from "../../data";

import { ReactComponent as FacebookIcon } from "./icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "./icons/twitter.svg";
import { ReactComponent as LinkedInIcon } from "./icons/linkedin.svg";
import { ReactComponent as YouTubeIcon } from "./icons/youtube.svg";
import { ReactComponent as WhatsAppIcon } from "./icons/whatsapp.svg";
import { ReactComponent as TelegramIcon } from "./icons/telegram.svg";

const iconsMap = new Map<string, ElementType>([
  ["facebook", FacebookIcon],
  ["instagram", InstagramIcon],
  ["twitter", TwitterIcon],
  ["linkedin", LinkedInIcon],
  ["youtube", YouTubeIcon],
  ["whatsapp", WhatsAppIcon],
  ["telegram", TelegramIcon],
]);

interface SocialMediaBarProps {
  color?:
    | "inherit"
    | "action"
    | "disabled"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
  htmlColor?: string;
  fontSize?: "inherit" | "small" | "medium" | "large";
}

const SocialMediaBar: FC<SocialMediaBarProps> = (
  props: SocialMediaBarProps
) => {
  return (
    <Box className={styles.socialIcons}>
      {socialMediaLinks.map((s, i) => {
        return (
          <Link key={`social-icon-${i}`} href={s.link} aria-label={s.name}>
            <SvgIcon
              inheritViewBox
              component={iconsMap.get(s.name.toLowerCase())!}
              {...props}
            ></SvgIcon>
          </Link>
        );
      })}
    </Box>
  );
};

export default SocialMediaBar;
