import { Grid } from '@mui/material';
import { FC, ReactNode } from 'react';
import ImageCard from '../ImageCard/ImageCard';

interface InfoGraphicContentProps {
  title: string 
  image: string 
  description?: string
  flipped?: boolean
  children?: ReactNode
  imageBorderRadius?: string | number
  titleFontFamily?: string
  titleFontWeight?: string
  titleFontSize?: string | number
  }

const InfoGraphicContent: FC<InfoGraphicContentProps> = (props: InfoGraphicContentProps) => (
    <Grid
      container
      direction={props.flipped ? "row" : "row-reverse"}
      columnSpacing={4}
      rowSpacing={3}
    >
      <Grid item xs={12} sm={6}>
        <p
          style={{
            fontSize: props.titleFontSize ?? 24,
            fontWeight: props.titleFontWeight ?? "bold",
            fontFamily: props.titleFontFamily
          }}
        >
          {props.title}
        </p>
        <br />
        {props.children ?? 
          <p style={{textAlign:"justify", fontSize: 16, lineHeight: 1.67, fontWeight: "light", paddingTop:'0.6rem' }}>
            {props.description}
          </p>
        }
      </Grid>
      <Grid item xs={12} sm={6}>
        <ImageCard imageUrl={props.image} height={360} borderRadius={props.imageBorderRadius} />
      </Grid>
    </Grid>
);

export default InfoGraphicContent;
