import loadData from "./loader";

export interface OurCoffeeEntry {
  title: string;
  briefDescription: string;
  landingImage: string;
  description: string;
  galleryImages: Array<string>;
}

export const ourCoffeeEntries = loadData<OurCoffeeEntry>("ourCoffee.json");
