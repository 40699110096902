import { useTheme } from "@mui/material";
import { FC } from "react";

interface RoundedArrowProps {
  color?: string;
  innerColor?: string;
  height?: number;
  width?: number;
  angle?: number;
}

const RoundedArrow: FC<RoundedArrowProps> = (props: RoundedArrowProps) => {
  const theme = useTheme();

  return (
    <svg
      style={{ transform: `rotate(${props.angle ?? 0}deg)` }}
      width={props.height ?? 100}
      height={props.width ?? 50}
      viewBox="0 0 91 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1575 65.1358C56.4496 71.3649 47.4631 75.1739 37.587 75.1739C16.8283 75.1739 0 58.3457 0 37.587C0 16.8283 16.8283 0 37.587 0C47.4631 0 56.4496 3.80902 63.1575 10.0381L63.3043 9.8913L91 37.587L63.3043 65.2826L63.1575 65.1358Z"
        fill={props.color ?? theme.palette.primary.main}
      />
      <circle cx="37" cy="38" r="17" fill="white" />
      <circle
        cx="37"
        cy="38"
        r="13"
        fill={props.innerColor ?? theme.palette.divider}
      />
      <rect
        x="24.4678"
        y="43.7971"
        width="3.95652"
        height="11.8696"
        rx="1.5"
        transform="rotate(60 24.4678 43.7971)"
        fill="white"
      />
    </svg>
  );
};

export default RoundedArrow;
