import { Fragment } from "react";

import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import { useCookies } from "react-cookie";

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SplashDialog from "../SplashDialog/SplashDialog";
import { CookieKey } from "../../config";

const Layout = () => {
  const [cookies] = useCookies([CookieKey.lastVisitDate]);

  return (
    <Fragment>
      <Header />
      <Box paddingY={8}>
        <Outlet />
      </Box>
      <Footer />

      {cookies[CookieKey.lastVisitDate] ? null : <SplashDialog />}
    </Fragment>
  );
};

export default Layout;
