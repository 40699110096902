import { Card, CardContent, Stack } from "@mui/material";
import styles from "./HomeCard.module.scss";

const HomeCard = (props: any) => {
    return (
        <Card sx={{ borderRadius: "0 100px"}}>
            <CardContent>
                    <h1 className={styles.title}> {props.title} </h1>
                    <h1 className={styles.body}> {props.body} </h1>
            </CardContent>
        </Card>
    )
}

export default HomeCard;