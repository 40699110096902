import { Grid } from "@mui/material";
import HomeSection1 from "./HomeSection1";
import HomeSection2 from "./HomeSection2";
import HomeSection3 from "./HomeSection3";

const HomePage = () => {

  return (
    <Grid container justifyContent="center" rowSpacing={10}>
      <Grid item xs={11} md={8} lg={8}>
        <HomeSection1></HomeSection1>
      </Grid>
      <Grid item xs={12}>
        <HomeSection2></HomeSection2>
      </Grid>
      <Grid item xs={12}>
        <HomeSection3></HomeSection3>
      </Grid>
    </Grid>
  );
};

export default HomePage;
