import {
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Logo from "../Logo/Logo";
import { default as routes } from "../../routes";

import styles from "./Header.module.scss";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const currentRoute = routes.findIndex(
    (r) => r.routeLink === location.pathname
  );
  const [selectedPage, setSelectPage] = useState(currentRoute);

  return (
    <Grid
      container
      className={styles.appHeader}
      justifyContent="center"
      style={{
        background: `url("${routes[selectedPage].headerBackground}") 0 0/cover no-repeat ${routes[selectedPage].headerOverlayColor}`,
      }}
    >
      <Grid item lg={9} xl={7} xs={12}>
        <Stack justifyContent="center" display="flex" textAlign="center">
          <Box sx={{ margin: 2 }}>
            <Logo />
          </Box>
          <Tabs
            className={styles.navBar}
            value={selectedPage}
            orientation={isMobile ? "vertical" : "horizontal"}
          >
            {routes.map((r, i) => (
              <Tab
                key={i}
                value={i}
                label={r.label}
                className={styles.navItem}
                onClick={() => setSelectPage(i)}
                component={Link}
                to={r.routeLink}
              />
            ))}
          </Tabs>

          <Box marginY={8} marginX={{ md: 24, xs: 4 }}>
            {
              isMobile ? <h1 style={{ fontSize: 50, color: theme.palette.primary.main, wordWrap: "break-word"}}>
              {routes[selectedPage].headerTitle}
            </h1> : <h1 style={{ fontSize: 64, color: theme.palette.primary.main, wordWrap: "break-word"}}>
              {routes[selectedPage].headerTitle}
            </h1>
            }
            
            <p className={styles.descriptiveText}>
                {routes[selectedPage].headerDescription}
            </p>
          </Box>
          {routes[selectedPage] != null ? (
            <Grid container item xs={12} justifyContent={"center"}>
              <Stack
                spacing={{ xs: 1, md: 3 }}
                marginBottom={8}
                direction={isMobile ? "column" : "row"}
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                {routes[selectedPage].actions}
              </Stack>
            </Grid>
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Header;
