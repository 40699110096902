import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CoffeeologyEntry } from "../../data/coffeeology";
import InfoGraphicContent from "../InfoGraphicContent/InfoGraphicContent";
import Blog from "../Blog/Blog";

export interface CoffeeologyItemProps extends CoffeeologyEntry {
  flipped?: boolean;
  readMore?: boolean;
  __html?: string;
}

const ReadMore = ( {children, id} : any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const [isBlogOpen, setIsBlogOpen] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const toggleBlog = () => {
    setIsBlogOpen(current => !current);
  };
  const readFull = (
      <IconButton disableRipple onClick={toggleBlog}>
        <p style={{textAlign: 'justify', fontSize: 16, lineHeight: 1.67, fontWeight: "light" }}>
          Read Full Blog
        </p>
        <MoreHorizIcon style={{ fontSize: 50 }} />
      </IconButton>
  );

  return (
    <>
      <p style={{ textAlign:'justify', fontSize: 16, lineHeight: 1.67, fontWeight: "light"}}>
        <p className="p2 hello" style={{paddingTop: '1rem'}} dangerouslySetInnerHTML={{__html: isReadMore ? text.slice(0, 150) : text.slice(0, 300)}}></p>
        <br/>
        <span onClick={toggleReadMore}>
          <IconButton
            style={{ margin: 0, padding: 0 }}
            onClick={toggleReadMore}
            edge="end"
            disableRipple
          >
            {isReadMore ? (
              <>
                <p style={{ fontSize: 16, marginLeft: 4, marginRight: 4 }}>
                  Continue Reading
                </p>
                <ExpandMoreIcon />{" "}
              </>
            ) : (
              <ExpandLessIcon />
            )}
          </IconButton>
        </span>
      </p>
      {isReadMore ? null : readFull}
      <Blog id={id} isBlogOpen={isBlogOpen} toggleBlog={toggleBlog} />
    </>
  );
};

const CoffeeologyItem: FC<CoffeeologyItemProps> = (
  props: CoffeeologyItemProps
) => {
  return (
  <InfoGraphicContent title={props.title} image={props.image} flipped={props.flipped}>
        {props.readMore ? (
          <ReadMore id={props.id}>{props.description.slice(0,150)}</ReadMore>
        ) : (
          <p className="p1 hello" style={{textAlign:"justify", fontSize: 16, lineHeight: 1.67, fontWeight: "light" }} dangerouslySetInnerHTML={{__html: props.description}}></p>
        )}
  </InfoGraphicContent>
  );
};
export default CoffeeologyItem;
