import { Divider, Grid } from "@mui/material";
import { companyInfoEntries, teamMembersEntries } from "../../data";
import CompanyInfoItem, {
  CompanyInfoItemProps,
} from "../../components/CompanyInfoItem/CompanyInfoItem";
import ImageCard from "../../components/ImageCard/ImageCard";
import { Stack } from "@mui/system";

const MeetPage = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={11} lg={8} xl={7}>
        <Stack rowGap={8}>
          {companyInfoEntries.map((entry, i) => {
            let entryProps: CompanyInfoItemProps = {
              ...entry,
            };

            if (i % 2 === 0) {
              entryProps.flipped = true;
            }

            return <CompanyInfoItem key={i} {...entryProps} />;
          })}

          <Divider style={{ opacity: 0.1 }} />
          <Grid
            container
            columnSpacing={4}
            rowSpacing={4}
            justifyContent="center"
          >
            {teamMembersEntries.map((i) => {
              return (
                <>
                  <Grid item xs={11} md={6} lg={3}>
                    <ImageCard
                      height={250}
                      imageUrl={i.image}
                      subTitle={i.name}
                    />
                    <p style={{marginTop: '1.6rem'}}>{i.title}</p>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default MeetPage;
