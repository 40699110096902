import { Grid, Stack } from "@mui/material";
import { FC, useState } from "react";
import { OurCoffeeEntry } from "../../data/ourCoffee";
import DisplayButton from "../DisplayButton/DisplayButton";
import InfoGraphicContent from "../InfoGraphicContent/InfoGraphicContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { BottomSheet } from "react-spring-bottom-sheet";
import GalleryView from "../GalleryView/GalleryView";

import "react-spring-bottom-sheet/dist/style.css";

export interface OurCoffeeItemProps extends OurCoffeeEntry {
  flipped?: boolean;
}

const OurCoffeeItem: FC<OurCoffeeItemProps> = (props: OurCoffeeItemProps) => {
  const [open, setOpen] = useState(false);

  return (
    <InfoGraphicContent
      title={props.title}
      image={props.landingImage}
      flipped={props.flipped}
      imageBorderRadius={20}
      titleFontFamily={"kurale"}
      titleFontSize={38}
    >
      <Stack rowGap={3} justifyContent={"space-between"} alignItems={"center"}>
        <p
          style={{
            textAlign: "justify",
            fontSize: 16,
            lineHeight: 1.67,
            fontWeight: "light",
            fontFamily: "inter",
            whiteSpace: 'pre-line',
            verticalAlign: 'bottom'
          }}
          dangerouslySetInnerHTML={{__html: props.briefDescription}}
        >
        </p>
        <div
          style={{
            height: 8,
            width: 24,
            borderRadius: 10,
            backgroundColor: "#d9d9d988",
          }}
        ></div>
        <DisplayButton
          color="#FD8F00"
          backgroundColor="transparent"
          onClick={() => setOpen(true)}
        >
          VIEW OPTIONS
          <MoreHorizIcon style={{ fontSize: 48 }} />
        </DisplayButton>
      </Stack>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        snapPoints={(snap) => [snap.maxHeight * 0.7, snap.maxHeight]}
        expandOnContentDrag={true}
        header={<h2>{props.title}</h2>}
      >
        <Grid container justifyContent="center" style={{ padding: "16px 0" }}>
          <Grid item container xl={7} lg={8} rowGap={2}>
            <GalleryView {...props} />
          </Grid>
        </Grid>
      </BottomSheet>
    </InfoGraphicContent>
  );
};

export default OurCoffeeItem;
