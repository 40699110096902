import React from "react";
import { Colors } from "./styles/theme";

import {
  AboutPage,
  CoffeeologyPage,
  ContactPage,
  HomePage,
  OurCoffeePage,
  MeetPage,
} from "./pages/";
import DisplayButton from "./components/DisplayButton/DisplayButton";

export interface RouteDefinition {
  name: string;
  label: string;
  routeLink: string;
  headerTitle: string;
  headerDescription: string;
  headerBackground: string;
  headerOverlayColor: string;
  component: React.ReactNode;
  actions?: Array<React.ReactNode>;
}

export default [
  {
    name: "default",
    label: "Home",
    routeLink: "/",
    headerTitle: "Coffee from the land of the Ancient Sabaeans",
    headerDescription: "",
    headerBackground: "/assets/img/png/banners/home-banner.png",
    headerOverlayColor: Colors.brown,
    component: <HomePage />,
    actions: [],
  },
  {
    name: "coffeeology",
    label: "Coffeeology",
    routeLink: "/Coffeeology",
    headerTitle: "Coffeeology",
    headerDescription: "“What are the mysteries behind this drink? For decades many people have shared their discoveries until in 2001 Gloria Montenegro invented the term Coffeology and thus it deserves the term” Coffee is truly a science and we like to call ourselves Coffeists”.",
    headerBackground: "/assets/img/png/banners/coffeeology-banner.png",
    headerOverlayColor: Colors.green,
    component: <CoffeeologyPage />,
  },
  {
    name: "our-coffee",
    label: "Our Coffee",
    routeLink: "/OurCoffee",
    headerTitle: "Our Coffee",
    headerDescription: "“We would like welcome you to coffee from the mysterious land of the Ancient Sabaeans currently known as Ethiopia & Yemen”",
    headerBackground: "/assets/img/png/banners/our-coffee-banner.png",
    headerOverlayColor: Colors.brown,
    component: <OurCoffeePage />,
  },
  {
    name: "about",
    label: "History of Coffee",
    routeLink: "/About",
    headerTitle: "History",
    headerDescription: "Coffee is one of the world's most popular beverages, and it has a long and interesting history.",
    headerBackground: "/assets/img/png/banners/history-banner.png",
    headerOverlayColor: Colors.green,
    component: <AboutPage />,
  },
  {
    name: "meet",
    label: "Meet The Team",
    routeLink: "/Meet",
    headerTitle: "Our Leadership Team",
    headerDescription: "\"With over 30 years combined experience, we've got a well-seasoned Captain & Crew operating the fleet of ships carrying your Coffee.",
    headerBackground: "/assets/img/png/banners/meet-the-team.png",
    headerOverlayColor: Colors.brown,
    component: <MeetPage />,
  },
  {
    name: "contact",
    label: "Contact Us",
    routeLink: "/Contact",
    headerTitle: "Lets share a moment",
    headerDescription: "As the British would say - \"let's put the Kettle on\". We would love to hear from your and also share our excitement, So get in touch!",
    headerBackground: "/assets/img/png/banners/contact-us-banner.png",
    headerOverlayColor: Colors.green,
    component: <ContactPage />,
  },
] as Array<RouteDefinition>;
