import loadData from "./loader";

export interface TeamMemberEntry {
  title: string;
  name: string;
  image: string;
}

export const teamMembersEntries =
  loadData<TeamMemberEntry>("teamMembers.json");
