import { FC, useState } from "react";

import { Box, Grid } from "@mui/material";
import { useCookies } from "react-cookie";

import PlayArrowIcon from "@mui/icons-material/PlayArrowRounded";
import ChevronRight from "@mui/icons-material/ChevronRight";

import DisplayButton from "../DisplayButton/DisplayButton";
import Logo from "../Logo/Logo";
import styles from "./SplashDialog.module.scss";
import { CookieKey } from "../../config";
import SocialMediaBar from "../SocialMediaBar/SocialMediaBar";
import ReactPlayer from "react-player";

interface SplashDialogProps {}

const SplashDialog: FC<SplashDialogProps> = () => {
  const [visible, setVisible] = useState(true);
  const [, setCookie] = useCookies([CookieKey.lastVisitDate]);

  return visible ? (
    <Grid container className={styles.screenOverlay}>
      <Grid item xs={0} md={2} className={styles.sidePanel} />

      <Grid item xs={12} md={10} lg={8} className={styles.content}>
        <Box sx={{ margin: "32px auto 0 auto" }}>
          <Logo />
        </Box>

        <Box className={styles.videoBox} flex={2}>
          <ReactPlayer url='https://youtu.be/9YTxYLpQTjk' loop={true} width="100%" height="100%" />
        </Box>

        <Box className={styles.bottomBar}>
          <SocialMediaBar htmlColor="white" />

          <DisplayButton
            backgroundColor="transparent"
            onClick={() => {
              setCookie(CookieKey.lastVisitDate, Date(), {
                sameSite: true,
                secure: false,
              });
              setVisible(false);
            }}
          >
            <ChevronRight /> CONTINUE
          </DisplayButton>
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

export default SplashDialog;
