import loadData from "./loader";

export interface CompanyInfoEntry {
  title: string;
  description: string;
  image: string;
}

export const companyInfoEntries =
  loadData<CompanyInfoEntry>("companyInfo.json");
