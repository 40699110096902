import loadData from "./loader";

export interface OurStoryEntry {
  title: string;
  description: string;
  image: string;
}

export const ourStoryEntries =
  loadData<OurStoryEntry>("ourStory.json");
