import React, { FC } from 'react';
import styles from './Logo.module.scss';

interface LogoProps { size?: number }

const Logo: FC<LogoProps> = (props: LogoProps) => (
  <img src="/assets/img/png/lana_logo.png" alt="logo" style={{ width: props.size ?? 128 }} />
);

export default Logo;
