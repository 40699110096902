import { Grid, Stack } from "@mui/material";
import CoffeeologyItem, {
  CoffeeologyItemProps,
} from "../../components/CoffeeologyItem/Coffeeology";
import { coffeeologyEntries } from "../../data/coffeeology";

const CoffeeologyPage = () => (
  <Grid container justifyContent="center">
    <Grid
      item
      lg={3}
      position={"relative"}
      display={{ xs: "none", lg: "block" }}
    >
      <img
        src="/assets/img/svg/coffeeologyLeftIcon.svg"
        style={{
          position: "absolute",
          bottom: 0,
        }}
      />
    </Grid>
    <Grid item xs={11} lg={6}>
      <Stack rowGap={8}>
        {coffeeologyEntries.map((entry, i) => {
          let entryProps: CoffeeologyItemProps = {
            ...entry,
          };

          if (i % 2 === 0) {
            entryProps.flipped = true;
          }
          entryProps.readMore = true

          return <CoffeeologyItem key={i} {...entryProps} />;
        })}
      </Stack>
    </Grid>
    <Grid
      item
      lg={3}
      position={"relative"}
      display={{ xs: "none", lg: "block" }}
    >
      <img
        src="/assets/img/svg/coffeeologyRightIcon.svg"
        style={{
          position: "absolute",
          right: 0,
          width: 250,
          height: 447,
        }}
      />
    </Grid>
  </Grid>
);

export default CoffeeologyPage;
