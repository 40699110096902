import { ThemeProvider } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { default as routes } from "./routes";
import { CookiesProvider } from "react-cookie";

import Layout from "./components/Layout/Layout";
import theme from "./styles/theme";

const App = () => {
  const location = useLocation();

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            {routes.map((r, i) => (
              <Route
                key={i}
                index={r.routeLink === location.pathname}
                path={r.routeLink}
                element={r.component}
              />
            ))}
          </Route>
        </Routes>
      </ThemeProvider>
    </CookiesProvider>
  );
};

export default App;
